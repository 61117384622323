<template>
  <div>
    <div class="pa-3">
      <h1 class="page-title">
        Администрирование
      </h1>
    </div>
    <v-container fluid>
      <v-tabs
        hide-slider
        height="32"
        fixed-tabs
        v-model="currentTab"
        class="base-tabs mb-4"
        active-class="base-tabs__active"
      >
        <v-tab
          v-for="itemTab in adminTabs.items"
          :key="itemTab.name"
          class="text-capitalize"
        >
          {{ itemTab.label }}
        </v-tab>
      </v-tabs>
    </v-container>
    <div>
      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="itemTab in adminTabs.items" :key="itemTab.name">
          <component :is="itemTab.components" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
const UserTab = () => import("@/components/AdminDashboard/UserTab");
const RolesTab = () => import("@/components/AdminDashboard/RolesTab");

export default {
  components: {
    UserTab,
    RolesTab,
  },
  data() {
    const adminTabs = {
      items: [
        {
          label: "Пользователи",
          name: "Users",
          components: UserTab,
        },
        {
          label: "Роли",
          name: "Roles",
          components: RolesTab,
        },
      ],
    };
    let currentTab = null;
    return {
      currentTab,
      adminTabs,
    };
  },

  computed: {},
};
</script>
